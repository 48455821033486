import DeveloperProductivity from "../content/developer-productivity.md";
import MentorshipHalfWayReflection from "../content/mentorship-half-way-reflection.md";
import Summary2023 from "../content/summary-2023.md";
import Team12023 from "../content/team1-2023.md";
import Team22023 from "../content/team2-2023.md";
import Team32023 from "../content/team3-2023.md";
import TechnicalWorkshops2024 from "../content/technical-workshops-2024.md";
import ToolsForTeam from "../content/tools-for-team.md";
import UsingGit from "../content/using-git.md";

const allFiles = [
  TechnicalWorkshops2024,
  Summary2023,
  Team12023,
  Team22023,
  Team32023,
  MentorshipHalfWayReflection,
  DeveloperProductivity,
  ToolsForTeam,
  UsingGit
];

export default allFiles;
