export const mentorshipFolks = {
  khoale: {
    name: 'Khoa Le',
    alias: 'khoale',
    title: 'Software Engineer @ Vanta',
    hobbies: 'board games, chess, reality shows',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/q_100/v1577895922/portfolio/avatar.jpg',
    term: [
      {
        year: 2023,
        keyword: 'organizer',
        role: 'Program Lead / Mentor',
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Program Lead / Mentor',
      },
    ],
  },
  huyanh: {
    name: 'Huy Anh',
    alias: 'huyanh',
    title: 'Software Engineer @ Netflix',
    hobbies: 'cats, startups, capybara',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083192/viettech/huyanh.jpg',
    term: [
      {
        year: 2023,
        keyword: 'organizer',
        role: 'Mentor',
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Lead / Mentor',
      },
    ],
  },
  chanle: {
    name: 'Chan Le',
    alias: 'chanle',
    title: 'Viet Tech Founder',
    hobbies: 'real estate, real estate, real estate',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083254/viettech/chanle.jpg',
    term: [
      {
        year: 2023,
        keyword: 'organizer',
        role: 'Mentor',
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Program Advisor',
      },
    ],
  },
  nguyenvu: {
    name: 'Nguyen Vu',
    alias: 'nguyenvu',
    title: 'Software Engineer @ Mastercard',
    hobbies: 'hiking, nature, cooking',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083321/viettech/nguyen.jpg',
    term: [
      {
        year: 2023,
        keyword: 'organizer',
        role: 'Mentor',
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Lead / Mentor',
      },
    ],
  },
  thuanh: {
    name: 'Thu Anh',
    alias: 'thuanh',
    title: 'Software Engineer @ Mastercard',
    hobbies: 'reading, writing, corgi',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083321/viettech/thuanh.jpg',
    term: [
      {
        year: 2023,
        keyword: 'organizer',
        role: 'Mentor',
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Lead / Mentor',
      },
    ],
  },
  kevinluu: {
    name: 'Kevin Luu',
    alias: 'kevinluu',
    title: 'Software Engineer @ Anyscale',
    hobbies: 'travel, pickle ball, badminton',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083321/viettech/kevin.jpg',
    term: [
      {
        year: 2023,
        keyword: 'organizer',
        role: 'Mentor',
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Lead / Mentor',
      },
    ],
  },
  jennyquach: {
    name: 'Jenny Quach',
    alias: 'jennyquach',
    title: 'SWE Intern @ LinkedIn',
    hobbies: 'board games, anime, food',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/jenny.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'LinkedIn',
            type: 'Internship',
          },
          {
            name: 'Amazon',
            type: 'Internship',
          },
          {
            name: 'Wayfair',
            type: 'Internship',
          },
          {
            name: 'BNY Mellon',
            type: 'Internship',
          },
        ],
        teamName: 'Productify',
        teamNumber: 3,
        teamRanking: 1,
        teammates: ['dangpham', 'robinhoang', 'phuocong'],
        mentors: ['kevinluu'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Lead / Mentor',
      },
    ],
  },
  sonmac: {
    name: 'Son Mac',
    alias: 'sonmac',
    title: 'Software Engineer @ Vanta',
    hobbies: 'frontend, cats, travel',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083321/viettech/son.jpg',
    term: [
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  nickdoan: {
    name: 'Nick Doan',
    alias: 'nickdoan',
    title: 'Software Engineer @ Palantir',
    hobbies: 'coding, running, games',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083513/viettech/nick.jpg',
    term: [
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  dangpham: {
    name: 'Dang Pham',
    alias: 'dangpham',
    title: 'SWE Intern @ ServiceNow',
    hobbies: 'reading, writing, gaming',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/dang.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'ServiceNow',
            type: 'Internship',
          },
          {
            name: 'Lilly',
            type: 'Internship',
          },
        ],
        teamName: 'Productify',
        teamNumber: 3,
        teamRanking: 1,
        teammates: ['jennyquach', 'robinhoang', 'phuocong'],
        mentors: ['huyanh'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  robinhoang: {
    name: 'Robin Hoang',
    alias: 'robinhoang',
    title: 'SWE Intern @ Microsoft',
    hobbies: 'solo trip, pole dance, beach',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/robin.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'Microsoft',
            type: 'Internship',
          },
          {
            name: 'MongoDB',
            type: 'Internship',
          },
          {
            name: 'Bank of America',
            type: 'Internship',
          },
        ],
        teamName: 'Productify',
        teamNumber: 3,
        teamRanking: 1,
        teammates: ['dangpham', 'jennyquach', 'phuocong'],
        mentors: ['khoale', 'thuanh'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  phuocong: {
    name: 'Phuoc Ong',
    alias: 'phuocong',
    title: 'SWE Intern @ Meta',
    hobbies: 'gym, food, dog',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/phuoc.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'Meta',
            type: 'Internship',
          },
          {
            name: 'ServiceNow',
            type: 'Internship',
          },
          {
            name: 'Niantic',
            type: 'Internship',
          },
        ],
        teamName: 'Productify',
        teamNumber: 3,
        teamRanking: 1,
        teammates: ['dangpham', 'jennyquach', 'robinhoang'],
        mentors: ['nguyenvu'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  khuetang: {
    name: 'Khue Tang',
    alias: 'khuetang',
    title: 'SWE Intern @ LinkedIn',
    hobbies: 'pekking duck, cooking, hiking',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/khue.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'LinkedIn',
            type: 'Internship',
          },
          {
            name: 'Proofpoint',
            type: 'Internship',
          },
        ],
        teamName: 'Safe travel',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['phuongcao', 'ngocdoan', 'cuongnguyen'],
        mentors: ['khoale'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  phuongcao: {
    name: 'Phuong Cao',
    alias: 'phuongcao',
    title: 'SWE Intern @ Adobe',
    hobbies: 'pekking duck, anime, music',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/minhphuong.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'Adobe',
            type: 'Internship',
          },
          {
            name: 'Amazon',
            type: 'Internship',
          },
        ],
        teamName: 'Safe travel',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['khuetang', 'ngocdoan', 'cuongnguyen'],
        mentors: ['kevinluu'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  ngocdoan: {
    name: 'Ngoc Doan',
    alias: 'ngocdoan',
    title: 'SWE Intern @ DoorDash',
    hobbies: 'road trip, food, concert',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/placeholder.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'DoorDash',
            type: 'Internship',
          },
          {
            name: 'Wayfair',
            type: 'Internship',
          },
        ],
        teamName: 'Safe travel',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['khuetang', 'phuongcao', 'cuongnguyen'],
        mentors: ['nguyenvu'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  cuongnguyen: {
    name: 'Cuong Nguyen',
    alias: 'cuongnguyen',
    title: 'SWE Intern @ GoDaddy',
    hobbies: 'board games, PC games, anime',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/cuong.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'GoDaddy',
            type: 'Internship',
          },
          {
            name: 'Wayfair',
            type: 'New Grad',
          },
        ],
        teamName: 'Safe travel',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['khuetang', 'phuongcao', 'ngocdoan'],
        mentors: ['khoale'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  quanle: {
    name: 'Quan Le',
    alias: 'quanle',
    title: 'SWE Intern @ Meta',
    hobbies: 'cosplay, board games, music',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/minhquan.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'Meta',
            type: 'Internship',
          },
          {
            name: 'ServiceNow',
            type: 'Internship',
          },
          {
            name: 'Matson Money',
            type: 'Internship',
          },
        ],
        teamName: 'Financial app',
        teamNumber: 1,
        teamRanking: 3,
        teammates: ['trangvu', 'hieuvuong', 'thanhnguyen'],
        mentors: ['chanle'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  trangvu: {
    name: 'Trang Vu',
    alias: 'trangvu',
    title: 'Student @ York University',
    hobbies: 'board games, K-drama, flowers',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711084156/viettech/trangvu.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Financial app',
        teamNumber: 1,
        teamRanking: 3,
        teammates: ['quanle', 'hieuvuong', 'thanhnguyen'],
        mentors: ['huyanh'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Tourific',
        teamNumber: 7,
        teamRanking: 7,
        teammates: ['trihoang', 'vannguyen', 'khatran'],
        mentors: ['huyanh'],
        projectAdvisors: ['nickdoan', 'khoale'],
      },
    ],
  },
  hieuvuong: {
    name: 'Hieu Vuong',
    alias: 'hieuvuong',
    title: 'SWE Intern @ SAP',
    hobbies: 'karaoke, beauty salon, travel',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/hieu.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        offers: [
          {
            name: 'SAP',
            type: 'Internship',
          },
        ],
        teamName: 'Financial app',
        teamNumber: 1,
        teamRanking: 3,
        teammates: ['quanle', 'trangvu', 'thanhnguyen'],
        mentors: ['nguyenvu', 'thuanh'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'organizer',
        role: 'Mentor',
      },
    ],
  },
  thanhnguyen: {
    name: 'Thanh Nguyen',
    alias: 'thanhnguyen',
    title: 'Student @ University of British Columbia',
    hobbies: 'beach, solo travel, writing',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711083621/viettech/thanh.jpg',
    term: [
      {
        year: 2023,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Financial app',
        teamNumber: 1,
        teamRanking: 3,
        teammates: ['quanle', 'trangvu', 'hieuvuong'],
        mentors: ['khoale'],
        projectAdvisors: ['khoale'],
      },
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'PeacePod',
        teamNumber: 6,
        teamRanking: 6,
        teammates: ['trangdo', 'haianh', 'namnguyen'],
        mentors: ['khoale'],
        projectAdvisors: ['khuetang', 'khoale'],
      },
    ],
  },
  hungngo: {
    name: 'Hung Ngo',
    alias: 'hungngo',
    title: 'Student @ University of Massachusetts Amherst',
    hobbies: 'keyboard, music, League',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711685228/viettech/hung.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Flavorie',
        teamNumber: 1,
        teamRanking: 1,
        teammates: ['ngocanh', 'vynguyen', 'viennguyen'],
        mentors: ['nickdoan'],
        projectAdvisors: ['jennyquach', 'phuongcao', 'khoale'],
      },
    ],
  },
  ngocanh: {
    name: 'Ngoc Anh',
    alias: 'ngocanh',
    title: 'Student @ Mount Holyoke College',
    hobbies: 'cooking, Chinese dramas, dogs',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711769961/viettech/ngocanh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Flavorie',
        teamNumber: 1,
        teamRanking: 1,
        teammates: ['hungngo', 'vynguyen', 'viennguyen'],
        mentors: ['khoale'],
        projectAdvisors: ['jennyquach', 'phuongcao', 'khoale'],
      },
    ],
  },
  vynguyen: {
    name: 'Vy Nguyen',
    alias: 'vynguyen',
    title: 'Student @ Catholic University of America',
    hobbies: 'music, cooking, travel',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711778695/viettech/vynguyen.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Flavorie',
        teamNumber: 1,
        teamRanking: 1,
        teammates: ['hungngo', 'ngocanh', 'viennguyen'],
        mentors: ['huyanh', 'jennyquach'],
        projectAdvisors: ['jennyquach', 'phuongcao', 'khoale'],
      },
    ],
  },
  viennguyen: {
    name: 'Vien Nguyen',
    alias: 'viennguyen',
    title: 'Student @ University of South Florida',
    hobbies: 'fencing, table tennis, travelling',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711819446/viettech/vien.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Flavorie',
        teamNumber: 1,
        teamRanking: 1,
        teammates: ['hungngo', 'ngocanh', 'vynguyen'],
        mentors: ['sonmac'],
        projectAdvisors: ['jennyquach', 'phuongcao', 'khoale'],
      },
    ],
  },
  allyhoang: {
    name: 'Ally Hoang',
    alias: 'allyhoang',
    title: 'Student @ Lehigh University',
    hobbies: 'cooking, embroidery, travel',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711170325/viettech/thucanh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Pick me food',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['giangpham', 'phananh', 'quynguyen'],
        mentors: ['khoale', 'jennyquach'],
        projectAdvisors: ['sonmac', 'khoale'],
      },
    ],
  },
  giangpham: {
    name: 'Giang Pham',
    alias: 'giangpham',
    title: 'Student @ Colby College',
    hobbies: 'Kpop, cooking, japanese books',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711262026/viettech/giang.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Pick me food',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['allyhoang', 'phananh', 'quynguyen'],
        mentors: ['thuanh', 'robinhoang'],
        projectAdvisors: ['sonmac', 'khoale'],
      },
    ],
  },
  phananh: {
    name: 'Phan Anh',
    alias: 'phananh',
    title: 'Student @ DePauw University',
    hobbies: 'soccer, running, coding',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711815305/viettech/phananh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Pick me food',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['allyhoang', 'giangpham', 'quynguyen'],
        mentors: ['khuetang'],
        projectAdvisors: ['sonmac', 'khoale'],
      },
    ],
  },
  quynguyen: {
    name: 'Quy Nguyen',
    alias: 'quynguyen',
    title: 'Student @ University of Windsor',
    hobbies: 'sports, soccer games, movies',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711341359/viettech/quy.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Pick me food',
        teamNumber: 2,
        teamRanking: 2,
        teammates: ['allyhoang', 'giangpham', 'phananh'],
        mentors: ['cuongnguyen'],
        projectAdvisors: ['sonmac', 'khoale'],
      },
    ],
  },
  thangnguyen: {
    name: 'Thang Nguyen',
    alias: 'thangnguyen',
    title: 'Student @ Toronto Metropolitan University',
    hobbies: 'badminton, basketball, video game',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711261900/viettech/thang.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Gathering globe',
        teamNumber: 3,
        teamRanking: 3,
        teammates: ['quynhtran', 'phuongle', 'quannguyen'],
        mentors: ['kevinluu'],
        projectAdvisors: ['quanle', 'khoale'],
      },
    ],
  },
  quynhtran: {
    name: 'Quynh Tran',
    alias: 'quynhtran',
    title: 'Student @ DePauw University',
    hobbies: 'cats, K-pop, travel',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711671347/viettech/quynh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Gathering globe',
        teamNumber: 3,
        teamRanking: 3,
        teammates: ['thangnguyen', 'phuongle', 'quannguyen'],
        mentors: ['thuanh'],
        projectAdvisors: ['quanle', 'khoale'],
      },
    ],
  },
  phuongle: {
    name: 'Phuong Le',
    alias: 'phuongle',
    title: 'Student @ Denison University',
    hobbies: 'films, novels, pop ballad',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711262168/viettech/phuongle.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Gathering globe',
        teamNumber: 3,
        teamRanking: 3,
        teammates: ['thangnguyen', 'quynhtran', 'quannguyen'],
        mentors: ['dangpham'],
        projectAdvisors: ['quanle', 'khoale'],
      },
    ],
  },
  quannguyen: {
    name: 'Quan Nguyen',
    alias: 'quannguyen',
    title: 'Student @ University of South Florida',
    hobbies: 'guitar, photography, fishing',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711432601/viettech/baquan.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Gathering globe',
        teamNumber: 3,
        teamRanking: 3,
        teammates: ['thangnguyen', 'quynhtran', 'phuongle'],
        mentors: ['phuocong'],
        projectAdvisors: ['quanle', 'khoale'],
      },
    ],
  },
  vytran: {
    name: 'Vy Tran',
    alias: 'vytran',
    title: 'Student @ Bucknell University',
    hobbies: 'soccer, mountains, dogs',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711832950/viettech/vytran.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Relationship management',
        teamNumber: 4,
        teamRanking: 4,
        teammates: ['linhtran', 'jimmynguyen', 'longdau'],
        mentors: ['nguyenvu'],
        projectAdvisors: ['phuocong', 'khoale'],
      },
    ],
  },
  linhtran: {
    name: 'Trang Linh',
    alias: 'linhtran',
    title: 'Student @ Hobart and William Smith Colleges',
    hobbies: 'draw, sleep, axolotl',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711839128/viettech/tranglinh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Relationship management',
        teamNumber: 4,
        teamRanking: 4,
        teammates: ['vytran', 'jimmynguyen', 'longdau'],
        mentors: ['ngocdoan'],
        projectAdvisors: ['phuocong', 'khoale'],
      },
    ],
  },
  jimmynguyen: {
    name: 'Jimmy Nguyen',
    alias: 'jimmynguyen',
    title: 'Student @ York University',
    hobbies: 'ice-skating, MUN, skywatching',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711290737/viettech/phuckhang.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Relationship management',
        teamNumber: 4,
        teamRanking: 4,
        teammates: ['vytran', 'linhtran', 'longdau'],
        mentors: ['phuongcao'],
        projectAdvisors: ['phuocong', 'khoale'],
      },
    ],
  },
  longdau: {
    name: 'Long Dau',
    alias: 'longdau',
    title: 'Student @ Texas Christian University',
    hobbies: 'sports, video game, rap',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711170436/viettech/long.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Relationship management',
        teamNumber: 4,
        teamRanking: 4,
        teammates: ['vytran', 'linhtran', 'jimmynguyen'],
        mentors: ['dangpham'],
        projectAdvisors: ['phuocong', 'khoale'],
      },
    ],
  },
  hoangnguyen: {
    name: 'Hoang Nguyen',
    alias: 'hoangnguyen',
    title: 'Student @ MacEwan University',
    hobbies: 'indie, k-drama, solo travel',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711129348/viettech/hoang.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Cupid',
        teamNumber: 5,
        teamRanking: 5,
        teammates: ['linhnguyen', 'linhhoang', 'khangnguyen'],
        mentors: ['hieuvuong'],
        projectAdvisors: ['dangpham', 'khoale'],
      },
    ],
  },
  linhnguyen: {
    name: 'Linh Nguyen',
    alias: 'linhnguyen',
    title: 'Student @ Tufts University',
    hobbies: 'food, music, roller coaster',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711817532/viettech/linhnguyen.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Cupid',
        teamNumber: 5,
        teamRanking: 5,
        teammates: ['hoangnguyen', 'linhhoang', 'khangnguyen'],
        mentors: ['phuongcao'],
        projectAdvisors: ['dangpham', 'khoale'],
      },
    ],
  },
  linhhoang: {
    name: 'Linh Hoang',
    alias: 'linhhoang',
    title: 'Student @ Dickinson College',
    hobbies: 'music, dumb tv shows, sunbathing',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711823122/viettech/khanhlinh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Cupid',
        teamNumber: 5,
        teamRanking: 5,
        teammates: ['hoangnguyen', 'linhnguyen', 'khangnguyen'],
        mentors: ['quanle'],
        projectAdvisors: ['dangpham', 'khoale'],
      },
    ],
  },
  khangnguyen: {
    name: 'Khang Nguyen',
    alias: 'khangnguyen',
    title: 'Student @ University of Massachusetts Amherst',
    hobbies: 'J-pop, gaming, anime',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711089916/viettech/huukhang.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Cupid',
        teamNumber: 5,
        teamRanking: 5,
        teammates: ['hoangnguyen', 'linhnguyen', 'linhhoang'],
        mentors: ['phuocong'],
        projectAdvisors: ['dangpham', 'khoale'],
      },
    ],
  },
  trangdo: {
    name: 'Trang Do',
    alias: 'trangdo',
    title: 'Student @ Northeastern University',
    hobbies: 'kdrama, cooking, Vpop',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711671186/viettech/trangdo.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'PeacePod',
        teamNumber: 6,
        teamRanking: 6,
        teammates: ['haianh', 'thanhnguyen', 'namnguyen'],
        mentors: ['nickdoan'],
        projectAdvisors: ['khuetang', 'khoale'],
      },
    ],
  },
  haianh: {
    name: 'Hai Anh',
    alias: 'haianh',
    title: 'Student @ Fordham University',
    hobbies: 'walking, bike riding, road trip',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711768766/viettech/haianh.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'PeacePod',
        teamNumber: 6,
        teamRanking: 6,
        teammates: ['trangdo', 'thanhnguyen', 'namnguyen'],
        mentors: ['khoale', 'robinhoang'],
        projectAdvisors: ['khuetang', 'khoale'],
      },
    ],
  },
  namnguyen: {
    name: 'Nam Nguyen',
    alias: 'namnguyen',
    title: 'Student @ Michigan State University',
    hobbies: 'soccer, One Piece, piano',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711261963/viettech/nam.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'PeacePod',
        teamNumber: 6,
        teamRanking: 6,
        teammates: ['trangdo', 'haianh', 'thanhnguyen'],
        mentors: ['jennyquach'],
        projectAdvisors: ['khuetang', 'khoale'],
      },
    ],
  },
  trihoang: {
    name: 'Tri Hoang',
    alias: 'trihoang',
    title: 'Student @ Rice University',
    hobbies: 'sports, traveling, movies',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711131353/viettech/tri.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Tourific',
        teamNumber: 7,
        teamRanking: 7,
        teammates: ['trangvu', 'vannguyen', 'khatran'],
        mentors: ['huyanh'],
        projectAdvisors: ['nickdoan', 'khoale'],
      },
    ],
  },
  vannguyen: {
    name: 'Van Nguyen',
    alias: 'vannguyen',
    title: 'Student @ Soka University',
    hobbies: 'kdrama, tarot, drums',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711420611/viettech/van.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Tourific',
        teamNumber: 7,
        teamRanking: 7,
        teammates: ['trihoang', 'trangvu', 'khatran'],
        mentors: ['sonmac'],
        projectAdvisors: ['nickdoan', 'khoale'],
      },
    ],
  },
  khatran: {
    name: 'Kha Tran',
    alias: 'khatran',
    title: 'Student @ University of Manitoba',
    hobbies: 'coding, reading, gaming',
    avatar:
      'https://res.cloudinary.com/khoa165/image/upload/v1711823667/viettech/kha.jpg',
    term: [
      {
        year: 2024,
        keyword: 'mentee',
        role: 'Mentee',
        teamName: 'Tourific',
        teamNumber: 7,
        teamRanking: 7,
        teammates: ['trihoang', 'trangvu', 'vannguyen'],
        mentors: ['khuetang'],
        projectAdvisors: ['nickdoan', 'khoale'],
      },
    ],
  },
};
