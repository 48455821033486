import React, { Fragment, useMemo } from 'react';
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  UncontrolledTooltip,
} from 'reactstrap';
import { FaArrowTrendUp, FaCode, FaHammer } from 'react-icons/fa6';
import { RiTeamFill } from 'react-icons/ri';
import Avatar from '../Layout/Avatar';
import MiniPeopleList from '../Layout/MiniPeopleList';

const AboutCard = ({ year, person }) => {
  const { name, alias, title, hobbies, avatar, term } = person;
  const currentTerm = useMemo(
    () => term.filter((t) => t.year === year)[0],
    [term, year]
  );
  const { role, keyword, mentors, projectAdvisors, teamName, teammates } =
    currentTerm;
  const offers = useMemo(
    () =>
      term
        .filter((t) => t.year === year)[0]
        .offers?.map((o) =>
          o.type === 'Internship' ? o.name : `${o.name} (NG)`
        ),
    [term, year]
  );

  return (
    <Card className="mentorship-about-card">
      <div className="info-assignment">
        {mentors && (
          <CardSubtitle className="info-mentors app-flex af-right mt-1 mb-2">
            <FaArrowTrendUp id={`${alias}-mentors-icon`} />
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-mentors-icon`}
            >
              One-on-one mentors
            </UncontrolledTooltip>
            <MiniPeopleList peopleList={mentors} prefix={alias} />
          </CardSubtitle>
        )}

        {projectAdvisors && (
          <CardSubtitle className="info-advisors app-flex af-right mt-2">
            <FaCode id={`${alias}-advisors-icon`} />
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-advisors-icon`}
            >
              Project technical mentors
            </UncontrolledTooltip>
            <MiniPeopleList peopleList={projectAdvisors} prefix={alias} />
          </CardSubtitle>
        )}
      </div>
      <Avatar url={avatar} />
      <CardBody>
        <CardTitle>{name}</CardTitle>
        {role !== 'Mentee' && <CardSubtitle>{role}</CardSubtitle>}
        {teammates && (
          <div className="app-flex mt-2">
            <FaHammer id={`${alias}-build-icon`} /> {teamName}
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-build-icon`}
            >
              Group project
            </UncontrolledTooltip>
            <RiTeamFill id={`${alias}-teammates-icon`} />
            <UncontrolledTooltip
              placement="bottom"
              target={`${alias}-teammates-icon`}
            >
              Teammates
            </UncontrolledTooltip>
            <MiniPeopleList peopleList={teammates} prefix={alias} />
          </div>
        )}
        <hr />
        <CardSubtitle className="about-title">{title}</CardSubtitle>
        <CardSubtitle>{hobbies}</CardSubtitle>
        {offers && keyword === 'mentee' && (
          <Fragment>
            <hr />
            <CardSubtitle className="about-offers">
              Offers: {offers.join(', ')}
            </CardSubtitle>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default AboutCard;
