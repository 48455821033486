export const getFileName = (filepath) => {
	// Example: /static/media/something-fun.7142a8c9d1daebf8832a.md
	const fullFileName = filepath.split('/')[3];
	const partialFileName = fullFileName.split('.')[0];
	return partialFileName;
};

export const removeMetadata = (text) => {
	const metaRegExp = RegExp(/^---[\s\S]*?---\s*/);
	const match = metaRegExp.exec(text);
	if (match) {
		return text.slice(match[0].length);
	}
	return text;
};

export const buildFileMetadata = (filepaths, metadata) => {
	const mapping = {};
	metadata.forEach(({ name, ...props }) => {
		mapping[name] = { name, ...props };
	});
	filepaths.forEach((filepath) => {
		mapping[getFileName(filepath)].path = filepath;
	});
	return mapping;
};

export const buildMetadataFileOutput = (metadata) =>
	`const blogsMetadata = [\n${metadata
		.map(
			(m) =>
				'  {\n    ' +
				Object.entries(m)
					.map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
					.join(',\n    ') +
				'\n  },'
		)
		.join('\n')}\n];\n\nexport default blogsMetadata;\n`;

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
const generateImportName = (filename) =>
	filename
		.split('-')
		.map((word) => capitalize(word))
		.join('');

export const buildFilePathsFileOutput = (metadata) => {
	const fileNames = metadata.map((m) => m.name);
	metadata.sort((a, b) => b.date - a.date);

	const importPart = fileNames
		.map(
			(name) =>
				`import ${generateImportName(name)} from "../content/${name}.md";`
		)
		.join('\n');

	const arrayPart = `const allFiles = [\n${metadata
		.map(({ name }) => '  ' + generateImportName(name))
		.join(',\n')}\n];\n\nexport default allFiles;\n`;

	return importPart + '\n\n' + arrayPart;
};
