import React from 'react';
import moment from 'moment';
import {
	Badge,
	Card,
	CardText,
	CardTitle,
	CardSubtitle,
	CardBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const BlogCard = ({
	blog: { name, title, author, description, date, tags },
}) => {
	return (
		<Card className='blog-card'>
			<CardBody>
				<CardTitle tag='h5'>{title}</CardTitle>
				<CardSubtitle className='mb-3 text-muted fst-italic' tag='h6'>
					<span>{moment(date).format('MMMM Do YYYY')}</span>
					<span className='mx-2'>|</span>
					<span>{author}</span>
				</CardSubtitle>
				<CardText className='mb-1'>{description} </CardText>
				<div className='mb-3'>
					{tags.map((tag) => (
						<Badge>{tag}</Badge>
					))}
				</div>
				<Link to={`${process.env.PUBLIC_URL}/${name}`}>
					Learn more
					<i className='bi bi-arrow-right ms-2' />
				</Link>
			</CardBody>
		</Card>
	);
};

export default BlogCard;
