import React, { useEffect } from 'react';
import AOS from 'aos';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './Layout/Navbar';
import AboutContainer from './About';
import GraphContainer from './Graph';
import ProjectsContainer from './Projects';
import TreeContainer from './Tree';
import '../styles/App.scss';

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="mentorship-website">
      <Router>
        <Navbar />
        <Switch>
          <Route path="/about">
            <AboutContainer />
          </Route>
          <Route path="/projects">
            <ProjectsContainer />
          </Route>
          <Route path="/graph">
            <GraphContainer />
          </Route>
          <Route path="/">
            <TreeContainer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
