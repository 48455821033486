import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import AboutPage from './AboutPage';

const AboutContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      {[2024, 2023].map((year) =>
        ['organizer', 'mentee'].map((keyword) => (
          <Route exact path={`${path}/${keyword}s/${year}`}>
            <AboutPage year={year} keyword={keyword} />
          </Route>
        ))
      )}
      <Redirect to={`${path}/organizers/2024`} />
    </Switch>
  );
};

export default AboutContainer;
