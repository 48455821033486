import React, { Fragment } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { getAvatar, getName } from '../../utils/data';
import Avatar from '../Layout/Avatar';

const MiniPeopleList = ({ peopleList, prefix }) => {
  return peopleList.map((p) => (
    <Fragment>
      <Avatar
        url={getAvatar(p)}
        id={`${prefix}-${p}`}
        alt={`Avatar of ${getName(p)}`}
        size="tiny"
      />
      <UncontrolledTooltip placement="bottom" target={`${prefix}-${p}`}>
        {getName(p)}
      </UncontrolledTooltip>
    </Fragment>
  ));
};

export default MiniPeopleList;
