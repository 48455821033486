import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Tree from './Tree';
import BlogPage from './BlogPage';
import { blogFilepaths, blogMetadata } from '../../blogs/metadata';
import { buildFileMetadata } from '../../utils/file';

const TreeContainer = () => {
	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		setMetadata(buildFileMetadata(blogFilepaths, blogMetadata));
	}, []);

	return (
		<div>
			{metadata !== null && (
				<Switch>
					<Route exact path='/'>
						<Tree metadata={metadata} />
					</Route>
					<Route exact path='/:filename'>
						<BlogPage metadata={metadata} />
					</Route>
				</Switch>
			)}
		</div>
	);
};

export default TreeContainer;
