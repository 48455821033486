import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../styles/Layout.scss';

const Banner = () => {
  return (
    <div id="mentorship-banner">
      <Row className="overlay">
        <Col xs="12" lg="8" className="left-section">
          <h1>Viet Tech Mentorship Program</h1>
          <p>
            Seeks to build a sustainable pipeline of early career talent and
            empower Vietnamese students to break into the tech industry
          </p>
          <Link to="/about/mentors/2024" className="about-us-btn light d-block">
            About us
          </Link>
        </Col>
        <Col lg="4" className="d-none d-lg-block">
          <img
            src="https://res.cloudinary.com/khoa165/image/upload/v1718192551/viettech/VTMP_logo.png"
            alt="Viet Tech logo banner"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
