import React from 'react';
import '../../styles/Layout.scss';

const Avatar = ({ url, size, ...props }) => {
  return (
    <div className="mentorship-avatar">
      <img
        className={`rounded-circle size-${size}`}
        src={url}
        alt="avatar"
        {...props}
      />
    </div>
  );
};

export default Avatar;
