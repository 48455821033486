import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { mentorshipFolks } from '../../people';
import AboutCard from './AboutCard';
import '../../styles/About.scss';
import { sortBy } from 'lodash';
import Select from 'react-select';

const YEAR = [2024, 2023];
const ROLE = ['organizer', 'mentee'];

const getYearOption = (year) => {
  return { value: year, label: year };
};

const getRoleOption = (role) => {
  return { value: role, label: role + 's' };
};

const AboutPage = ({ year, keyword }) => {
  const history = useHistory();

  const people = useMemo(() => {
    const filteredPeople = Object.values(mentorshipFolks).filter((p) =>
      p.term.some((t) => t.year === year && t.keyword === keyword)
    );
    if (keyword === 'organizer') {
      return filteredPeople;
    }
    return sortBy(filteredPeople, (p) => p.name);
  }, [year, keyword]);

  const totalOffers = people.reduce((p, c) => {
    const currentTerm = c.term.find((t) => t.year === year);
    return p + (currentTerm?.offers?.length ?? 0);
  }, 0);

  const yearOptions = useMemo(() => {
    return YEAR.map((y) => {
      return getYearOption(y);
    });
  }, []);

  const roleOptions = useMemo(() => {
    return ROLE.map((r) => {
      return getRoleOption(r);
    });
  }, []);

  if (people.length === 0) {
    return (
      <h3 className="mt-5 text-center text-green">
        Stay tuned! We are under recruiting season for our {year} season
      </h3>
    );
  }

  const onChangeYear = (selectedOption) => {
    history.push(`/about/${keyword}s/${selectedOption.value}`);
  };

  const onChangeRole = (selectedOption) => {
    history.push(`/about/${selectedOption.value.toLowerCase()}s/${year}`);
  };

  return (
    <div id="about-page">
      <div className="app-flex mb-4">
        <Select
          defaultValue={getYearOption(year)}
          onChange={onChangeYear}
          options={yearOptions}
          className="about-dropdown"
        />
        <Select
          defaultValue={getRoleOption(keyword)}
          onChange={onChangeRole}
          options={roleOptions}
          className="about-dropdown"
        />
      </div>
      {keyword === 'mentee' && totalOffers >= 10 && (
        <h3 className="achievement-text mb-5 text-center text-green">
          {year} cohort achievements so far: {totalOffers} offers
        </h3>
      )}
      <Row>
        {people.map((person) => (
          <Col lg="6" xl="4">
            <AboutCard year={year} person={person} className="about-card" />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AboutPage;
