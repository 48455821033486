import React, { useEffect } from 'react';
import BlogCard from './BlogCard';
import Banner from '../Layout/Banner';
import { blogFilepaths } from '../../blogs/metadata';
import { getFileName } from '../../utils/file';
import '../../styles/Tree.scss';

const Tree = ({ metadata }) => {
	const run = () => {
		const items = document.querySelectorAll('#tree-container li');
		items.forEach((item) => {
			if (isInView(item)) {
				item.classList.add('show');
			}
		});
	};

	const attachEventListeners = () => {
		window.addEventListener('scroll', run);
		window.addEventListener('load', run);
		window.addEventListener('resize', run);
	};

	const removeEventListeners = () => {
		window.removeEventListener('scroll', run);
		window.removeEventListener('load', run);
		window.removeEventListener('resize', run);
	};

	useEffect(() => {
		attachEventListeners();
		return removeEventListeners;
	}, []);

	const isInView = (item) => {
		const rect = item.getBoundingClientRect();
		return (
			rect.top + 100 >= 0 &&
			rect.left + 30 >= 0 &&
			rect.bottom - 100 <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right - 30 <=
				(window.innerWidth || document.documentElement.clientWidth)
		);
	};

	return (
		<div id='tree-container'>
			<Banner />
			<ul>
				{blogFilepaths.map((path) => {
					const name = getFileName(path);
					return (
						<li key={name}>
							<BlogCard blog={metadata[name]} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Tree;
